.templateMainSection {
  .templateBodyContainer {
    @include mainPadding;

    .templateLinkSection {
      @include linkSection;
      margin-bottom: 8px;
      .templateLink {
        @include links;
      }
    }
    .commonSectionAdmin {
      margin-top: 0px;
      .mainRow {
        margin-top: 3px;
        margin-bottom: 23px;
      }
    }

    .middleSection {
      margin-bottom: 10px;
      .tabsSection {
        text-align: center;

        .ant-tabs-nav {
          margin-bottom: 10px;
          &::before {
            border-bottom: 1px solid $gray11;
          }

          .ant-tabs-nav-wrap {
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;

            .ant-tabs-nav-list {
              .ant-tabs-tab {
                @include flexCenter;
                background: $gray12;
                border-bottom: 1px solid $gray11;
                border-right: none;
                border-top: none;
                height: 32px;
                margin-left: 0px;
                width: 103px;
                .ant-tabs-tab-btn {
                  @include grayText;
                }
              }
              .ant-tabs-tab-active {
                background-color: $white;
                border-top: 3px solid $blue9;
                border-right: 1px solid $gray11;
                border-left: 1px solid $gray11;
                border-bottom: none;
              }
            }
          }
          .ant-tabs-nav-operations {
            display: none;
          }
        }
      }

      .option {
        @include grayText;
        text-transform: capitalize;
      }

      .sectionOne {
        align-items: center;
        display: flex;
        flex-direction: row;

        .sectionOneRight {
          align-items: center;
          display: flex;
          justify-content: flex-end;
        }
        .sectionOneLeft {
          @include flexRow;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }

    .templateBtnSection {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 27px;
      .backBtn {
        @include back-btn;
      }
      .saveBtn {
        @include blue-backg-btn;
        border-radius: 5px;
        background-color: $blue9;
        border: 1px solid $blue9;
        margin-left: 21px;
        width: 170px;
      }
    }
  }
  .spin {
    @include flexCenter;
  }
}

@include mobile {
  .templateMainSection {
    .templateBodyContainer {
      padding: 0px 20px 0px 20px;

      .middleSection {
        .greetingHeading,
        .subHead {
          @include grayText;
          align-items: center;
          display: flex;
          justify-content: space-between;
          text-transform: capitalize;
        }
        .option {
          @include grayText;
          text-transform: capitalize;
        }

        .sectionTwo {
          align-items: center;
          display: flex;
          flex-direction: row;

          .sectionTwoLeft {
            @include flexRow;
            justify-content: flex-start;
            align-items: center;
          }
          .sectionTwoRight {
            align-items: center;
            display: flex;
            justify-content: flex-end;
          }
        }

        .sectionOne {
          align-items: center;
          display: flex;
          flex-direction: row;

          .sectionOneRight {
            align-items: center;
            display: flex;
            justify-content: flex-end;
          }
          .sectionOneLeft {
            @include flexRow;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      .templateBtnSection {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 45px;
      }
    }
  }
}

@include tablet-landscape {
  .templateMainSection {
    .templateBodyContainer {
      padding: 0px 40px 0px 40px;

      .commonSectionAdmin {
        .colForm {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

@include tablet-portrait {
  .templateMainSection {
    .templateBodyContainer {
      padding: 0px 40px 0px 40px;

      .templateBtnSection {
        margin-bottom: 50px;
      }
    }
  }
}
