.footerContainer {
  background: $gray3;
  bottom: 0;
  height: 25px;
  position: absolute;
  width: 100%;
}

@include mobile  {
  .footerContainer {
    
    position: absolute;
  }
}
