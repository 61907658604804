@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
// font family
$roboto: "Roboto", sans-serif;

// colors
$red: red;
$red2: #f74e4d;
$white: #ffffff;
$gray1: #464a53;
$gray2: #f5f8fa;
$gray3: #f2f2f2;
$gray4: #dadada;
$gray5: #9ba3aa;
$gray6: #afbccb;
$gray7: #aaaaaa;
$gray8: #7f93ab;
$gray9: #c0bcbc;
$gray10: #77838f;
$gray10: #979797;
$gray11: #c5cbcf;
$gray12: #f5f8fa;
$gray13: #959fa8;
$gray14: #626476;
$gray15:#EAEAEA;
$blue1: #438afe;
$blue2: #0192ff;
$blue3: #3a86ff;
$blue4: #1a48a8;
$blue5: #4baddb;
$blue6: #1f9edb;
$blue7: #0f70eb;
$blue8: #4876b2;
$blue9: #1d56a3;
$blue10: #060c43;
$blue11: #4c69ff;
$darkBlue: #1f315f;
$darkblue2:#154360;
$darkblue3:#1C5FAD;
$black: #000000;
$green1:#84d13c;

// font weight
$fontweight1: 100;
$fontweight2: 200;
$fontweight3: 300;
$fontweight4: 400;
$fontweight5: 500;
$fontweight6: 600;
$fontweight7: 700;
$fontweight8: 800;
$fontweight9: 900;
