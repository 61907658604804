.headerContainer {
  position: relative;
  .headerMain {
    align-items: center;
    background: $gray3;
    box-shadow: 0.5px 3px 10px rgba(119, 119, 119, 0.1);
    display: flex;
    height: 42px;
    justify-content: flex-end;
    @include mainPadding;
    width: 100%;

    .logoImg {
      width: 136px;
    }
  }
  .logOutMain {
    display: flex;
    justify-content: flex-end;
    @include mainPadding;
    margin-top: 28px;
    margin-bottom: 28px;
    .logoutBtn {
      @include back-btn;
      border-radius: 4px;
      border: 0.5px solid $gray11;
      font-size: 11px;
      height: 24px;
      width: 72px;
    }
  }
}

@include mobile {
  .headerContainer {
    .headerMain,
    .logOutMain {
      padding: 0 20px 0px 20px;
    }
  }
}

@include tablet-portrait {
  .headerContainer {
    .headerMain,
    .logOutMain {
      padding: 0 40px 0px 40px;
    }
  }
}

@include tablet-landscape {
  .headerContainer {
    .headerMain,
    .logOutMain {
      padding: 0 40px 0px 40px;
    }
  }
}
