.viewModal {
  margin-right: 60px;
  .ant-modal-content {
    margin: 0 auto;
    overflow-y: auto;
    width: max-content;
    .ant-modal-close .ant-modal-close-x {
      display: none;
    }
    .ant-modal-header {
      display: none;
      background-color: $blue9;
      height: 44px;
      .ant-modal-title {
        display: none;
      }
    }
    .ant-modal-body {
      padding: 5px;

      .cardImgContainer {
        @include flexCenter;
        background-color: $darkblue2;
        flex-direction: column;
        padding: 22px 4px 22px 4px;
        position: relative;

        .cardImg {
          height: 400px;
          width: 563px;
        }
        .firstTextBox {
          @include textBox;
          padding-bottom: 20px;
          .cardTextFirst {
            @include whiteText;
            font-size: 15px;
          }
        }
        .lastTextBox {
          @include flexCol;
          @include textBox;
          padding-top: 20px;
          .cardLastText {
            @include whiteText;
            font-size: 15px;
          }
        }
      }
    }
    .ant-modal-footer {
      display: none;
      background-color: $gray3;
      height: 25px;
      .ant-btn-default,
      .ant-btn-primary {
        display: none;
      }
    }
  }
}
.viewModalPromotion {
  margin-right: 0px;
  .ant-modal-content {
    .ant-modal-close .ant-modal-close-x {
    }
    .ant-modal-header {
      .ant-modal-title {
        display: none;
      }
    }
    .ant-modal-body {
      .cardImgContainer {
        flex-direction: row;
        padding: 4px 4px 4px 4px;

        .cardImg {
          height: auto;
          width: 400px;
        }
      }
    }
    .ant-modal-footer {
    }
  }
}

@include mobile {
  .viewModal {
    margin: 0 auto;
    .ant-modal-content {
      .ant-modal-body {
        .cardImgContainer {
          .cardImg {
            height: 200px;
            width: 281px;
          }
          .firstTextBox {
            @include textBox;
            padding-bottom: 20px;
            height: 0px;
            .cardTextFirst {
              @include whiteText;
            }
          }
          .lastTextBox {
            @include flexCol;
            @include textBox;
            padding-top: 20px;
            height: 0px;
            .cardLastText {
              @include whiteText;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      background-color: $gray3;
      height: 25px;
      .ant-btn-default,
      .ant-btn-primary {
        display: none;
      }
    }
  }
  .viewModalPromotion {
    margin-right: 0px;
    .ant-modal-content {
      .ant-modal-close .ant-modal-close-x {
      }
      .ant-modal-header {
        .ant-modal-title {
          display: none;
        }
      }
      .ant-modal-body {
        .cardImgContainer {
          .cardImg {
            height: auto;
            width: 300px;
          }
        }
      }
      .ant-modal-footer {
      }
    }
  }
  // .viewModal {
  //   margin: 0 auto;
  //   .ant-modal-content {
  //    width: 100%;
  //   margin: 0 auto;
  //     .ant-modal-body {

  //       .cardImgContainer {

  //         .cardImg {
  //           height: 100%;
  //           width: 100%;
  //         }

  //       }
  //     }
  //     .ant-modal-footer {
  //       display: none;
  //       background-color: $gray3;
  //       height: 25px;
  //       .ant-btn-default,
  //       .ant-btn-primary {
  //         display: none;
  //       }
  //     }
  //   }
  // }
}

@include tablet-portrait {
  .viewModal {
    margin: 0 auto;
    .ant-modal-content {
      width: 100%;
      margin: 0 auto;
      .ant-modal-body {
        .cardImgContainer {
          .cardImg {
            height: 100%;
            width: 100%;
          }
        }
      }
      .ant-modal-footer {
        display: none;
        background-color: $gray3;
        height: 25px;
        .ant-btn-default,
        .ant-btn-primary {
          display: none;
        }
      }
    }
  }
  .viewModalPromotion {
    margin-right: 0px;
    .ant-modal-content {
      .ant-modal-close .ant-modal-close-x {
      }
      .ant-modal-header {
        .ant-modal-title {
          display: none;
        }
      }
      .ant-modal-body {
        .cardImgContainer {
          .cardImg {
            width: 100%;
            height: auto;
          }
        }
      }
      .ant-modal-footer {
      }
    }
  }
}

@include tablet-landscape {
  .viewModal {
    margin: 0 auto;
    .ant-modal-content {
      width: 100%;
      margin: 0 auto;
      .ant-modal-body {
        .cardImgContainer {
          .cardImg {
            height: 100%;
            width: 100%;
          }
        }
      }
      .ant-modal-footer {
        display: none;
        background-color: $gray3;
        height: 25px;
        .ant-btn-default,
        .ant-btn-primary {
          display: none;
        }
      }
    }
  }
  .viewModalPromotion {
    margin-right: 0px;
    .ant-modal-content {
      .ant-modal-close .ant-modal-close-x {
      }
      .ant-modal-header {
        .ant-modal-title {
          display: none;
        }
      }
      .ant-modal-body {
        .cardImgContainer {
          .cardImg {
            width: 100%;
            height: auto;
          }
        }
      }
      .ant-modal-footer {
      }
    }
  }
}
