.subHead {
  @include grayText;
  line-height: 20px;
  text-transform: capitalize;
}
.imgUploadContainer {
  background-color: $gray2;
  height: 274px;
  margin-top: 5px;
  padding: 19px 25px;
  position: relative;

  .uploadRightSection {
    .uploadRightSectionLeft {
      .uploadImageLabel {
        color: $gray1;
        font-size: 14px;
        font-style: normal;
        font-weight: $fontweight4;
        line-height: 24px;
        margin-bottom: 6px;
      }
      .uploadInput {
        background: $white;
        border-radius: 5px;
        border: 1px solid $blue9;
        color: $blue9;
        font-weight: $fontweight5;
        height: 35px;
        margin-bottom: 26px;
        margin-top: 0;
        width: 100%;
      }
      .uploadTemplateBtn {
        @include blue-border-btn;
        margin-bottom: 6px;
        width: 156px;

        .uploadIcon {
          height: 17px;
          margin-left: 10px;
        }
      }
     .uploadTemplateBtnPromotion{
  width: 200px;
     }

      .uploadTemplateBtnPromotion {
        @include blue-border-btn;
        margin-bottom: 6px;
        width: 200px;

        .uploadIcon {
          height: 17px;
          margin-left: 10px;
        }
      }
      .errorMessage {
        @include errorText;
      }

      .fileFormatBox {
        margin-top: 5px;
      
        .BestViewText {
          color: $gray1;
          font-size: 11px;
          font-weight: $fontweight7;
        }
        .fileDetailsHead {
          color: $gray1;
          font-size: 10px;
          font-weight: $fontweight7;
        }
        .fileDetailText {
          color: $gray1;
          font-size: 10px;
          font-weight: $fontweight4;
          margin-left: 5px;
          
        }
      }
    }
  }
  .uploadImageCol {
    display: flex;
    justify-content: flex-end;
    .uploadRightSectionRight {
      margin-right: 0px;
      .uploadViewImgSection {
        @include flexCol;
        align-items: center;
        margin-top: 10px;
        .wishImg {
          height: 133px;
          margin-bottom: 4px;
          width: 187px;
        }
        .wishImgPromotion {
          height: 208px;
          width: 157px;
        }
        .viewBtnSection {
          display: flex;
          align-items: center;
          margin-left: 130px;
          .viewBtn {
            @include blue-border-btn;
            height: 30px;
            margin-bottom: 8px;
            margin-top: 4px;
            width: 60px;
          }
        }
        .viewBtnSectionPromotion {
          margin-left: 100px;
        }
      }
     

    }
  }
}

.imgUploadContainerPromotion{
  
  padding: 10px 20px;
}



@include mobile {
  .imgUploadContainer {
    height: 480px;
    padding: 19px 15px;
    .uploadImageCol {
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
      .uploadRightSectionRight {
        .uploadViewImgSection {
          @include flexCol;
          align-items: center;
          .wishImg {
            height: 183px;
            margin-bottom: 4px;
            width: 100%;
          }
          .wishImgPromotion {
            height: 208px;
            width: 157px;
          }
          .viewBtnSection {
            display: flex;
            margin-left: 230px;
            .viewBtn {
              @include blue-border-btn;
              height: 30px;
              margin-bottom: 8px;
              margin-top: 4px;
              width: 60px;
            }
          }
          .viewBtnSectionPromotion {
            margin-left: 100px;
          }
        }
      }

      .uploadRightSectionLeft {
        .uploadTemplateBtn {
          margin-bottom: 6px;
        }
      }
    }
  }
}

@include tablet-landscape {
  .imgUploadContainer {
    padding-left: 20px;
    padding-right: 20px;

    .uploadRightSection {
      .uploadRightSectionLeft {
        .uploadTemplateBtn {
          margin-bottom: 6px;
          width: 140px;
        }
        .fileFormatBox {
          width: 170px;
        }
      }
      .uploadRightSectionRight {
      
        .uploadViewImgSection {
    
          .wishImg {
            margin-left: 65px;
            height: 140px;
            width:230px;
            margin-bottom: 4px;
          }
          .wishImgPromotion {
            height: 200px;
            width: 100%;
            margin-left: 0;

          }
          .viewBtnSection {
            margin-left: 250px;
          }
          .viewBtnSectionPromotion {
            margin-left: 140px;
          }
        }
      }
    }
  }
  .imgUploadContainerPromotion{
  
    padding: 19px 25px;
  }
}

@include tablet-portrait {
  .imgUploadContainer {
    height: 310px;
    padding-left: 20px;
    padding-right: 20px;

    .uploadRightSection {
      .uploadRightSectionLeft {
        .uploadTemplateBtn {
          margin-bottom: 6px;
          width: 200px;

          .uploadIcon {
            color: red;
          }
        }
      }
    }
    .uploadRightSectionRight {
      margin-left: 0px;
      .uploadViewImgSection {
        .wishImg {
          height: 200px;
          width: 100%;
          margin-bottom: 4px;
        }
        .wishImgPromotion {
          height: 230px;
          width: 200px;
        

        }

        .viewBtnSection {
          display: flex;
          margin-left: 140px;
          .viewBtn {
            @include blue-border-btn;
            height: 30px;
            margin-bottom: 8px;
            margin-top: 4px;
            width: 60px;
          }
        }
      }
    }
  }
}
