.viewCardContainer {
  background-color: $gray2;
  position: relative;
  .viewCardLeftImageWrap {
    background-color: $white;
    border: 0.5px solid $gray5;
    margin: 40px 11px 40px 11px;
    padding: 4px;

    .viewCardLeft {
      @include backgroundImage;
      background-size: contain;
      height: 113px;
      width: 100%;
    }
  }
  .viewCardLeftImageWrapPromotion {
    margin: 22px 11px 22px 11px;
    padding: 5px;
  
  
    .viewCardLeftPromotion {
   
       height: 192px;
      width: 100%;
    }
  }
 

  


  .viewCardRight {
    padding: 24px 15px 40px 0px;
    position: relative;

    .inputFeildTemplate {
      background: $white;
      border: none;
      box-shadow: 0.5px 3px 10px rgba(119, 119, 119, 0.1);
      height: 35px;
      margin-bottom: 22px;
      margin-top: 0;
      width: 100%;
    }
    .templateFormLabel {
      color: $gray1;
      font-size: 14px;
      font-style: normal;
      font-weight: $fontweight4;
      line-height: 24px;
      margin-bottom: 0px;
    }

    .formTemplateBottom {
      position: relative;

      .viewLinkSection {
        display: flex;
        justify-content: flex-end;

        .viewLink {
          color: $blue9;
          font-size: 14px;
          font-style: normal;
          font-weight: $fontweight5;
        }
      }
      .dotsPopup {
        color: $gray5;
        position: absolute;
        right: -12px;
        top: -98px;
      }
      .dotsPopupPromotion {

      }
    }
  }
}

// popup stylings
.popUpEdit {
  @include flexCol;
  padding: 5px 17px;
  width: 114px;

  .divider {
    background-color: $gray9;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .linksEdit {
    color: $blue9;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: $fontweight4;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: left;
  }
}

@include mobile {
  .viewCardContainer {
    height: 490px;

    .viewCardLeftImageWrap {
      padding: 9px;
      .viewCardLeft {
        width: 100%;
        height: 200px;
      }
    }
    .viewCardLeftImageWrapPromotion {
      margin: 19px 11px 19px 11px;
      padding: 7px;
    
    
      .viewCardLeftPromotion {
     
         height: 400px;
        width: 100%;
      }
    }
    .viewCardRight {
      padding: 24px 15px 40px 15px;

      .formTemplateBottom {
        .dotsPopup {
          position: absolute;
          right: -15px;
          top: -380px;
        }
        .dotsPopupPromotion {
          top: -335px;
        }
      }
    }
  }
  .popUpEdit {
    @include flexCol;
    padding: 5px 15px;
    width: 100px;
  }
}

@include tablet-portrait {
  .viewCardContainer {
    margin-bottom: 20px;

    .viewCardLeftImageWrapPromotion {
      
      padding: 10px;
    
    
      .viewCardLeftPromotion {
     
        
       width:100%;
      }
    }
    
  }
}

@include tablet-landscape {
  .viewCardContainer {
    margin-bottom: 20px;
    .viewCardLeftImageWrap {
      padding: 5px;
      .viewCardLeft {
        width: 100%;
      }
    }
    
    .viewCardLeftPromotion {
     
      height:177px;
      width:100%;
     }
  }
}
