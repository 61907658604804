@mixin flexCol {
  display: flex;
  flex-direction: column;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin flex {
  align-items: center;
  display: flex;
}
@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin mainPadding {
  padding: 0px 95px 0px 95px;
}

@mixin linkSection {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  margin-bottom: 15px;
}

@mixin links {
  color: $gray1;
  font-size: 12px;
  font-style: normal;
  font-weight: $fontweight4;
  margin-left: 20px;
 

  // &:active,
  // &:focus {
  //   color: $blue1;
  //   font-weight: $fontweight7;
  // }
}
@mixin grayText {
  color: $gray1;
  font-size: 12px;
  font-weight: $fontweight5;
  margin: 0 !important;
}

@mixin greetingText {
  color: $blue6;
  font-size: 12px;
  font-weight: $fontweight7;
  margin: 0 !important;
  text-align: center;
}

@mixin errorText {
  color: $red2;
  font-size: 12px;
  font-weight:$fontweight4;
  line-height: 14px;
  margin-bottom: 7px;

}

@mixin backgroundImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


@mixin textBox {
  height: 20px;
  @include flexCol;
  justify-content: center;
  align-items: center;
}
@mixin whiteText {
  color: $white;
  font-size: 10px;
  font-weight: $fontweight4;
  text-transform: capitalize;
}

$mobile-width-mx: 760px;
$mobile-width-mn: 360px;
$tablet-width: 760px;
$laptop-width: 992px;
$desktop-width: 1024px;
$desktop-large-width: 1280px;
$desktop-width_s: 1366px;

@mixin mobile {
  @media (min-width: #{$mobile-width-mn}) and (max-width:#{$mobile-width-mx}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin small-desktop {
  @media (min-width:#{$desktop-width}) and (max-width:#{$desktop-large-width -1px}) {
    @content;
  }
}

@mixin tablet-landscape {
  @media only screen and (min-width:#{$laptop-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet-portrait {
  @media only screen and (min-width:#{$tablet-width}) and (max-width: #{$laptop-width}) {
    @content;
  }
}
