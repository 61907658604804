.templateViewMainContainer {
  .templateViewBodyContainer {
    @include mainPadding;
    .templateLinkSection {
      @include linkSection;
      .templateLink {
        @include links;
      }
    }
    .middleSection {
      margin-bottom: 10px;

      .tabsSection {
        text-align: center;
        .ant-tabs-nav {
          margin-bottom: 10px;
          &::before {
            border-bottom: 1px solid $gray11;
          }

          .ant-tabs-nav-wrap {
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;
            .ant-tabs-nav-list {
              .ant-tabs-tab {
                @include flexCenter;
                background: $gray12;
                border-bottom: 1px solid $gray11;
                height: 32px;
                margin-left: 0px;
                width: 103px;
                .ant-tabs-tab-btn {
                  @include grayText;
                }
              }
              .ant-tabs-tab-active {
                background-color: $white;
                border-top: 3px solid $blue9;
                border-right: 1px solid $gray11;
                border-left: 1px solid $gray11;
                border-bottom: none;
              }
            }
          }
          .ant-tabs-nav-operations {
            display: none;
          }
        }
      }
      .checkboxOptions {
        @include grayText;
      }

      .sectionOne {
        align-items: center;
        display: flex;
        flex-direction: row;

        .sectionOneRight {
          align-items: center;
          display: flex;
          justify-content: flex-end;
        }
        .sectionOneLeft {
          @include flexRow;
          align-items: center;
          justify-content: flex-start;

          .greetingsPromoHeading {
            @include grayText;
          }
          p {
            margin: 0 !important;
          }
        }
      }
    }
    .cardGallerySection {
      .galleryRow {
        margin-bottom: 18px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
      }
    }

    .viewListBtnSection {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 78px;
      margin-top: 20px;
      .backBtn {
        @include back-btn;
      }
      .nextBtn {
        @include blue-backg-btn;
        background-color: $blue9;
        margin-left: 21px;
        width: 170px;
      }
    }
  }
  .viewFooter {
    margin-top: 0px;
  }

  .noDataTextBox {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: center;
    .noDataImg {
      height: 175px;
    }

    .oopsText {
      color: $darkBlue;
      font-family: Montserrat;
      font-size: 25px;
      font-weight: 600;
      line-height: 30px;
      margin-top: 5px;
    }
  }
  .spin {
    @include flexCenter;
  }
}

@include mobile {
  .templateViewMainContainer {
    .templateViewBodyContainer {
      padding: 0px 20px 0px 20px;

      .middleSection {
        margin-bottom: 10px;

        .sectionOne {
          align-items: center;
          display: flex;
          flex-direction: row;

          .sectionOneRight {
            align-items: center;
            display: flex;
            justify-content: flex-end;
          }
          .sectionOneLeft {
            @include flexRow;
            align-items: center;
            justify-content: flex-start;

            .greetingsPromoHeading {
              @include grayText;
            }
            p {
              margin: 0 !important;
            }
          }
        }
      }
    }

    .noDataTextBox {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 400px;
      justify-content: center;
      .noDataImg {
        height: 120px;
      }

      .oopsText {
        color: $darkBlue;
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
        margin-top: 5px;
        text-align: center;
      }
    }
    .spin {
      @include flexCenter;
    }
  }
}

@include tablet-portrait {
  .templateViewMainContainer {
    .templateViewBodyContainer {
      padding: 0px 40px 0px 40px;
    }
  }
}

@include tablet-landscape {
  .templateViewMainContainer {
    .templateViewBodyContainer {
      padding: 0px 40px 0px 40px;
    }
  }
}
