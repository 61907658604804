.notificationBox {
  background-color: $white;
  height: max-content;
  padding: 20px 30px;
  text-align: center;
  width: 280px;

  .ant-notification-notice-content {
    font-size: 23px;

    .ant-notification-notice-icon {
      align-items: center;
      color: $red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative !important;

      .errorImage {
        height: 24px;
        width: 24px;
      }
    }
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      color: $gray10;
      font-size: 15px;
      font-weight: $fontweight5;
      line-height: 18px;
      margin: 0 !important;
      padding-right: 0 !important;
      text-align: center;
    }

    .ant-notification-notice-description {
      margin-left: 0px !important;
    }
  }

  .ant-notification-notice-close {
    display: none;
  }
}

.notificationBoxTwo {
  @extend .notificationBox;

  .ant-notification-notice-content {
    .ant-notification-notice-icon {
      color: $green1;
    }
  }
}
