.ant-input {
  &:focus {
    box-shadow: none;
    border-color: none;
  }
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.ant-input-password .inputfeild .ant-input {
  background-color: $white;

  &:focus {
    background-color: $white !important;
  }
}

.ant-input-affix-wrapper .ant-input-password .inputFeild {
  .ant-input {
    background-color: $white !important;

    &:focus,&:active,&:hover {
      background-color: red;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: $blue10 !important;
  background-color: #ffffff !important;
  font-weight: $fontweight5;
 
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  
}


.ant-btn {
  &:focus,
  &:active,
  &:hover {
    border-color: transparent;
    box-shadow: none;
  }
}
a.ant-btn {
  line-height: 30px;
  padding-top: 4px !important;
}

.ant-checkbox-input,
.ant-checkbox-wrapper {
  &:active,
  &:focus,
  &:visited,
  &:focus-visible {
    color: $blue9;
  }
}

.ant-checkbox-wrapper-checked {
  color: $blue9 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  opacity: 0;
}

.ant-pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  margin-top: 20px;
  .ant-pagination-item {
    border-radius: 50%;
    height: 34px;
    width: 34px;
    background-color: $white;

    &:hover,
    &:active,
    &:focus {
      background-color: $blue9;
      a {
        color: $white;

        &:hover,
        &:active,
        &:focus,
        &:visited,
        &:focus-visible {
          color: $white !important;
        }
      }
    }
  }
  .ant-pagination-item a {
    color: $blue9;

    &:hover,
    &:active,
    &:focus,
    &:visited,
    &:focus-visible {
      color: $white;
    }
  }

  .ant-pagination-item-link {
    border-radius: 50%;
    height: 34px;
    width: 34px;
  }
}

.ant-popover-inner-content {
  padding: 0px;
}

.deactivateModal {
  .ant-modal-content {
    width: 411px;
    .ant-modal-close .ant-modal-close-x {
      display: none;
    }
    .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding: 15px 30px;

      .deactivateText {
        color: $gray10;
        font-size: 15px;
        font-style: normal;
        font-weight: $fontweight5;
        line-height: 18px;
        text-align: center;
      }
      .deactivateBtnSection {
        @include flexCenter;

        .noBtn {
          background: $red2;
          border-radius: 5px;
          border: 1px solid $red2;
          box-sizing: border-box;
          color: #ffffff;
          font-size: 15px;
          font-style: normal;
          font-weight: $fontweight7;
          height: 44px;
          width: 119px;
        }

        .yesBtn {
          background: #84d13c;
          border-radius: 5px;
          border: 1px solid #84d13c;
          color: #ffffff;
          height: 44px;
          margin-left: 18px;
          width: 119px;
        }
      }
    }
  }

  .ant-modal-footer {
    display: none;
    .ant-btn-default,
    .ant-btn-primary {
      display: none;
    }
  }
}

.uploadErrModel {
  .ant-modal-content {
    height: 114px;
    text-align: center;
    width: 411px;
    .ant-modal-close .ant-modal-close-x {
      display: none;
    }
    .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding: 24px 47px;
      .errorBox {
        @include flexRow;
        align-items: center;
        justify-content: center;
        margin-bottom: 11px;
        text-align: center;
        .uploadErrorImage {
          height: 24px;
          width: 24px;
        }
        p {
          color: $gray10;
          font-size: 18px;
          font-weight: $fontweight5;
          margin-bottom: 0 !important;
          margin-left: 10px;
          margin-top: 0 !important;
        }
      }

      .maximumText {
        color: $gray10;
        font-size: 15px;
        font-weight: $fontweight5;
        margin-bottom: 0 !important;
        margin-top: 11px;
      }
    }
  }

  .ant-modal-footer {
    display: none;
    .ant-btn-default,
    .ant-btn-primary {
      display: none;
    }
  }
}

.ant-row-center {
  justify-content: space-between;
}

@include mobile {
  .deactivateModal {
    .ant-modal-content {
      width: 100%;
    }
  }
}

@include tablet-portrait {
  .deactivateModal {
    .ant-modal-content {
      width: 100%;
    }
  }
}

@include tablet-portrait {
  .deactivateModal {
    .ant-modal-content {
      width: 100%;
    }
  }
}

@include tablet-landscape {
  .deactivateModal {
    .ant-modal-content {
      width: 100%;
    }
  }
}
