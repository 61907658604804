.loginContainer {
  height: 100vh;
  position: relative;
  width: 100%;
  .leftSectionLogin {
    @include backgroundImage;
    @include flex;
    align-items: flex-end;
    height: 100vh;
    padding-bottom: 63px;
    width: 100%;
    .loginGreetingHeadingBox {
      background-color: $blue8;
      border-radius: 0px 35px 35px 0px;
      height: 94px;
      margin-left: 19px;
      opacity: 0.8;
      padding: 10px;
      width: 374px;
      .greetingsHead {
        color: $white;
        font-size: 19px;
        font-weight: $fontweight5;
        line-height: 24px;
        text-transform: capitalize;
      }
      .loginText {
        color: $white;
        font-size: 12px;
        font-weight: $fontweight4;
        line-height: 14.5px;
        text-align: justify;
      }
    }
  }
  .rightSectionLogin {
    height: 100vh;
    text-align: left;
    .logoSectionBox {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      height: 10vh;
      justify-content: flex-end;
      .logoLogin {
        height: 45px;
        margin-top: 29px;
        width: 195px;
      }
    }
    .logoSectionMain {
      display: flex;
      justify-content: center;
      .loginSectionBoxTwo {
        @include flexCol;
        justify-content: center;
        height: 90vh;
        justify-content: center;
        text-align: left;
        .logoWithLogin {
          @include flexCol;
          text-align: left;
          .headingLogin {
            color: $blue10;
            font-size: 35px;
            font-weight: $fontweight6;
          }
          .promoText {
            color: $gray14;
            display: flex;
            font-size: 14px;
            font-weight: $fontweight4;
            margin-bottom: 28px;
          }
        }
        .inputSection {
          position: relative;
      
          .label {
            background-color: $white;
            color: $gray14;
            font-size: 12px;
            font-weight: $fontweight4;
            margin-left: 12px;
            position: relative;
            top: 23px;
            width: max-content;
            z-index: 99;
          }

          .errorMessage {
            @include errorText;
          }

          .inputFeild {
            &.ant-input-affix-wrapper > input.ant-input {
              padding: 0;
              border: none;
              outline: none;
              text-align: center;
            }

            background: $white;
            border-radius: 5px;
            border: 1px solid $gray15;
            font-size: 14px;
            font-weight: $fontweight5;
            height: 43px;
            margin-bottom: 5px;
            position: relative;
            text-align: center;
            width: 369px;

            &:focus,
            &:active,
            &:hover {
              color: $blue10;
              font-weight: $fontweight5;
            }
          }
        }
        .continueBtn {
          @include blue-backg-btn;
          margin-top: 29px;
        }
      }
    }
    .uploadImage {
      display: flex;
    }
  }
}

@include mobile {
  .loginContainer {
    .leftSectionLogin {
      padding-right: 5px;
      .loginGreetingHeadingBox {
        margin-left: 5px;

        .loginText {
        }
      }
    }
    .rightSectionLogin {
      .logoSectionMain {
        padding-left: 10px;
        padding-right: 10px;

        .loginSectionBoxTwo {
          .logoWithLogin {
            @include flexCol;
            text-align: left;
          }
          .inputSection {
            .inputFeild {
              width: 100%;
            }
          }
          .continueBtn {
            width: 100%;
          }
        }
      }
    }
  }
}

@include tablet-portrait {
  .loginContainer {
    .leftSectionLogin {
      .loginGreetingHeadingBox {
        height: 150px;
        padding: 15px;
        width: 550px;
        .loginText {
          font-size: 20px;
          line-height: 25px;
          text-align: justify;
        }
      }
    }
  }
}
